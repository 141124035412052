<template
  #default="{ hide }"
>
  <div class="body">
    <b-card v-if="!loading">
      <travel-map
        class="travel-map"
        :markers="marker"
        :lines="[]"
        :last-location="true"
      />
    </b-card>
  </div>
</template>
<script>
import {
  BCard, VBTooltip,
} from 'bootstrap-vue'
import TravelMap from '@/views/rides/components/Map/TravelMap.vue'

export default {
  components: {
    BCard,
    TravelMap,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  data() {
    return {
      loading: false,
      scooterData: {},
      marker: [{
        id: 'start', position: { lat: 11, lng: 11 }, icon: 'start-marker.svg',
      }],
    }
  },
  mounted() {
    this.load()
  },
  methods: {
    load() {
      this.loading = true
      this.$http.get(this.$resources.scooters.fetch.replace(':id', this.$route.params.id)).then(response => {
        const { data: { status, data } } = response
        if (status === 200) {
          this.scooterData = data.scooter
          this.lines[0].position.lng = data.scooter.iot_location.lat
          this.lines[0].position.lat = data.scooter.iot_location.lng
          this.loading = false
        }
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.dev-contents-middle {
  display: flex;
  justify-content: center;
  align-items: center;
}
.locate-scooter {
  border-radius:10px;
  background-color: #e4e4e4;
  padding: 50px;
}
.locate-scooter-icon {
  width: 95px;
  height: 85px;
}
.travel-map {
  height: 600px !important;
}
</style>
